import moment from 'moment-business-days'
import pluralize from 'pluralize'

import { validate } from '@/core/plugins/validator'
import {
  email,
  isValidCardCode,
  isValidCreditCard,
  isValidExpiryDate,
  isZipcode,
  minLength,
  phone,
  required,
} from '@/core/plugins/validator/validators'
import { TechnologyTypes } from '@/core/utils/constants'

export default {
  isInjectionMolding(state) {
    return state.technologyId === TechnologyTypes.InjectionMolding
  },
  isSheetMetal(state) {
    return state.technologyId === TechnologyTypes.SheetMetal
  },
  isCNC(state) {
    return state.technologyId === TechnologyTypes.CNCMachining
  },
  isPrintingTechnology(state) {
    return state.technologyId === TechnologyTypes.Printing
  },
  isDefaultConfigurationModal(state, getters) {
    return !(getters.isInjectionMolding || getters.isSheetMetal)
  },
  technologies(state) {
    if (state.technologyId && state.technologyId === TechnologyTypes.Printing) {
      return state.technologies.filter(
        technology => !technology.manualQuotationRequired
      )
    }

    return state.technologies
  },
  status(state) {
    return state.currentStatus && state.currentStatus.name
      ? state.currentStatus.name
      : ''
  },
  somePartsHasManualQuotationReason(state) {
    return state.parts.some(part => part.manualQuotationReason)
  },
  reviewRequested(state, getters) {
    if (getters.isInjectionMolding) return true

    if (state.quoteType === 'Manual' && getters.status === 'Requested') {
      return true
    }

    if (getters.somePartsHasManualQuotationReason && getters.status !== 'Approved') return true

    return getters.allPartsConfigured && !getters.allPartsWithAcceptedPrice
  },
  requestApproved(state, getters) {
    return getters.status === 'Approved'
  },
  configuredParts(state) {
    return state.parts.filter(
      part =>
        (part.technologyId && part.materialId) ||
        (part.technologyId && part.imLifeOfToolId && part.finishId)
    )
  },
  unconfiguredPartsLength(state, getters) {
    return state.parts.length - getters.configuredParts.length
  },
  allPartsConfigured(state, getters) {
    return state.parts.length === getters.configuredParts.length
  },
  allPartsSelected(state, getters) {
    return getters.selectedParts.length === state.parts.length
  },
  manualQuotationParts(state) {
    return state.parts.filter(part => part.manualQuotationRequired)
  },
  selectedParts(state) {
    return state.parts.filter(part => part._selected)
  },
  selectedPartsIds(state, getters) {
    return getters.selectedParts.map(part => part.id)
  },
  allPartsWithAcceptedPrice(state) {
    const acceptedParts = state.parts.filter(part => {
      if (
        part.streamicsStatus === 'processed' &&
        part.price !== null &&
        part.price !== undefined &&
        !isNaN(part.price) &&
        part.price >= 1
      ) {
        return true
      }
    })

    return acceptedParts.length === state.parts.length
  },
  schedules(state) {
    if (state.summary.processSchedules) {
      const result = []

      Object.keys(state.summary.processSchedules).forEach(key => {
        if (key !== 'economy') {
          result.push({
            ...state.summary.processSchedules[key],
            value: key,
          })
        }
      })

      return result
    }

    return []
  },
  selectedScheduleItem(state, getters) {
    return getters.schedules.length
      ? getters.schedules.find(
          schedule => schedule.id === state.selectedSchedule
        )
      : null
  },
  feesList(state) {
    return state.summary.fees.map(item => {
      return {
        name: item.name,
        value: item.value,
        amount: item.amount,
      }
    })
  },
  subtotal(state) {
    return state.summary.subtotal ? state.summary.subtotal : '--'
  },
  total(state, getters) {
    if (!state.summary.total) {
      return '--'
    }

    return (
      +state.summary.total +
      +state.totalTax +
      +getters.shippingCostValue
    ).toFixed(2)
  },
  partsLength(state) {
    return state.parts.length
  },
  partsCountLabel(state) {
    return state.parts.length
      ? pluralize('part', state.parts.length, true)
      : '--'
  },
  deliveryDateDescription(state) {
    if (state.summary.deliveryDate) {
      const deliveryDate = moment(
        state.summary.processSchedules[state.selectedSchedule].shippedBy
      ).format('MMMM DD')
      return `Order within ${state.summary.deliveryDate.hours} hours and ${state.summary.deliveryDate.minutes} minutes <br /> for an estimated ship date of ${deliveryDate}`
    }
    return ''
  },
  shippingCostValue(state) {
    if (state.ownShippingCarrier) {
      return 0
    }
    if (state.shippingMethod) {
      const cost = state.shippingMethod.split('__')[1]
      if (!isNaN(cost)) {
        return parseFloat(cost)
      }
      return 0
    }
    return 0
  },
  shippingCost(state, getters) {
    return getters.shippingCostValue ? getters.shippingCostValue : '--'
  },
  totalTax(state) {
    return state.totalTax ? state.totalTax : '--'
  },
  taxRate(state) {
    return state.taxRate ? `(${state.taxRate.toFixed(2)}%)` : ''
  },
  checkoutValidations(state) {
    let validators = {}

    let shippingInfoValidators = {
      shippingFirstName: [required()],
      shippingLastName: [required()],
      shippingPhone: [required(), phone(), minLength(15)],
      shippingAddress: [required()],
      shippingState: [required()],
      shippingCity: [required()],
      shippingZipcode: [required(), isZipcode()],
    }

    let shippingPreferencesValidators = {}
    if (state.manualPickup) {
      shippingPreferencesValidators = {
        pickupFrom: [required()],
        pickupName: [required()],
      }
      shippingInfoValidators = {}
    } else {
      if (state.ownShippingCarrier) {
        shippingPreferencesValidators = {
          accountNumber: [required()],
          deliveryPreference: [required()],
        }
      } else {
        shippingPreferencesValidators = {
          shippingMethod: [required()],
        }
      }
    }

    const mainValidators = {
      billingFirstName: [required()],
      billingLastName: [required()],
      billingEmail: [required(), email()],
      billingPhone: [required(), phone(), minLength(15)],
      billingAddress: [required()],
      billingState: [required()],
      billingCity: [required()],
      billingZipcode: [required(), isZipcode()],
      creditCardNumber: [required(), isValidCreditCard()],
      creditCardExpiryDate: [required(), isValidExpiryDate()],
      creditCardCode: [required(), isValidCardCode()],
    }
    const purchaseValidators = {
      purchaseOrderEmail: [required(), email()],
      purchaseOrderPhone: [required(), phone(), minLength(15)],
      purchaseOrderNumber: [required()],
      purchaseOrderDocuments: [required()],
    }

    if (state.paymentMethod === 'credit-card') {
      if (state.shippingTheSameAsBilling) {
        validators = Object.assign(
          shippingPreferencesValidators,
          mainValidators
        )
      } else {
        validators = Object.assign(
          shippingPreferencesValidators,
          mainValidators,
          shippingInfoValidators
        )
      }
    } else {
      validators = Object.assign(
        purchaseValidators,
        shippingPreferencesValidators,
        shippingInfoValidators
      )
    }

    return validate(
      {
        billingFirstName: state.billingFirstName,
        billingLastName: state.billingLastName,
        billingEmail: state.billingEmail,
        billingPhone: state.billingPhone,
        billingAddress: state.billingAddress,
        billingState: state.billingState,
        billingCity: state.billingCity,
        billingZipcode: state.billingZipcode,
        creditCardNumber: state.creditCardNumber,
        creditCardExpiryDate: state.creditCardExpiryDate,
        creditCardCode: state.creditCardCode,
        shippingFirstName: state.shippingFirstName,
        shippingLastName: state.shippingLastName,
        shippingEmail: state.shippingEmail,
        shippingPhone: state.shippingPhone,
        shippingAddress: state.shippingAddress,
        shippingState: state.shippingState,
        shippingZipcode: state.shippingZipcode,
        shippingCity: state.shippingCity,
        accountZipcode: state.accountZipcode,
        shippingMethod: state.shippingMethod,
        accountNumber: state.accountNumber,
        deliveryPreference: state.deliveryPreference,
        purchaseOrderEmail: state.purchaseOrderEmail,
        purchaseOrderPhone: state.purchaseOrderPhone,
        purchaseOrderNumber: state.purchaseOrderNumber,
        purchaseOrderLink: state.purchaseOrderLink,
        purchaseOrderDocuments: state.purchaseOrderDocuments,
        pickupFrom: state.pickupFrom,
        pickupName: state.pickupName,
      },
      validators
    )
  },
  locked(state) {
    const status =
      state.currentStatus && state.currentStatus.name
        ? state.currentStatus.name
        : ''
    return (
      state.locked ||
      (state.quoteType === 'Manual' && status === 'Requested') ||
      status === 'Approved' ||
      state.expired ||
      status === 'No Quote'
    )
  },
  paymentMethod(state) {
    return state.paymentMethod
  },
  paymentError(state) {
    return state.paymentError
  },
  shipDate(state) {
    if (!state.selectedSchedule) {
      return null
    }

    return state.summary.processSchedules[state.selectedSchedule].shippedBy
  },
  minimumOrderValue(state) {
    return +state.summary.minimumOrder
  },
  totalDiscounts(state) {
    if (!state.summary.totalDiscounts) {
      return '--'
    }
    return +state.summary.totalDiscounts
  },

  totalFees(state) {
    if (!state.summary.totalFees) {
      return '--'
    }
    return +state.summary.totalFees
  },

  totalAddons(state) {
    if (!state.summary.totalAddons) {
      return '--'
    }
    return +state.summary.totalAddons
  },
  otherMaterialsIds(state) {
    const result = []
    state.technologies.forEach(technology => {
      Object.keys(technology['materials']).forEach(key => {
        if (
          technology['materials'][key]['title']
            .toUpperCase()
            .startsWith('OTHER')
        ) {
          result.push(parseInt(key))
        }
      })
    })
    return result
  },
  isNoQuote(state, getters) {
    return getters.status === 'No Quote'
  },
}
